<template>
	<section class="content">
	<div class="row">
		<div class="col-sm-12 col-md-5">
			<div class="card mb-3">
				<div class="card-body">
					<div class="h5">Kode Afiliasi {{ shipper_name ? shipper_name : 'Kamu' }}</div>
					<div class="row">
						<div class="col-sm-12 col-md form-control border border-warning" style="background-color: rgba(255, 114, 3, 0.25);font-weight: 700;">
							{{ this.recapAfiliasi.affiliator_id }}
						</div>
						<div class="col-sm-12 col-md-auto mt-xs-2 p-0 d-flex justify-content-between">
							<button class="btn btn-warning text-light w-49" @click="copyCode">
								<i class="fi fi-rr-copy mr-1"></i>Salin
							</button>
							<button class="btn btn-outline-warning w-49" @click="copyLink">
								<i class="fi fi-rr-share mr-1"></i>Bagikan
							</button>
						</div>
						<div class="col-sm-12 form-control mt-2 d-flex justify-content-between align-items-center" style="border: 1px solid rgba(108, 117, 125, 0.25);cursor: pointer;" @click="showStep">
							<span>Cara Menggunakan Kode Afiliasi</span>
							<i class="fi fi-rr-angle-right"></i>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-sm-12 col-md">
			<div class="card mb-3">
				<div class="card-body">
					<div class="h5">Komisi {{ shipper_name ? shipper_name : 'Kamu' }}</div>
					<div class="form-control" style="border: 1px solid rgba(108, 117, 125, 0.25);height: 100%;">
						<div class="row py-1">
							<div class="col-auto h-100">
								<div class="rounded-circle" style="background-color: rgba(32, 201, 151, 0.25);padding: .75rem .75rem .35rem;color: var(--teal);font-size: 1.5rem;">
									<i class="fi fi-rr-wallet"></i>
								</div>
							</div>
							<div class="col">
								<small class="text-grey">Saldo Komisi Tersedia <span class="text-secondary" ref="btntoolTip" @mouseover="mouseOver" data-toggle="tooltip" data-placement="top" data-html="true" title='<h6>Saldo yang didapatkan dari 1% ongkos kirim per paket yang dikirimkan oleh teman afiliasimu dan statusnya "Berhasil".</h6>'><i class="fa fa-info-circle"></i></span></small>
								<div class="h4 my-1" style="font-weight: 600;">Rp {{ this.recapAfiliasi.estimasi_reward }}</div>
							</div>
							<div class="col-12 col-sm-auto mt-2 btn btn-warning text-light" style="height: fit-content;" v-if="false">
								<i class="fi fi-rr-upload d-inline-block" style="transform: rotate(180deg);"></i> Tarik Komisi
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="card">
		<div class="card-body">
			<div class="row" style="row-gap: 0.5rem;">
				<div class="col-12 col-sm-auto">
					<div class="input-group">
						<input type="text" ref="daterange" class="form-control" style="border-right: 0" />
						<div class="input-group-append">
							<div class="input-group-text" style="background-color: #fff">
								<span><span class="fi fi-rr-calendar"></span></span>
							</div>
						</div>
					</div>
				</div>
				<div class="col-auto" v-if="false">
					<button type="button" @click="confirmRecon" :disabled="processing" class="btn btn-success">
						Rekonsiliasi
					</button>
				</div>
				<div class="col-12 col-sm-auto ml-auto" @click="showList">
					<button class="btn btn-block btn-outline-warning">Lihat Daftar Teman</button>
				</div>
			</div>
		</div>
		<table class="table table-hover" ref="tableuser" id="tablshipper">
			<thead>
				<tr>
					<th>PAYMENT STATUS</th>
					<th>No. RESI</th>
					<th>TANGGAL</th>
					<th class="text-left !important">KOMISI</th>
				</tr>
			</thead>
			<tbody @click="handleClick">
			</tbody>
		</table>
	</div>
	<div class="modal" tabindex="-1" role="dialog" ref="affiliateIntro" data-backdrop="static">
		<div class="modal-dialog d-flex align-items-center m-auto" role="document" style="height: 100vh">
			<div class="modal-content">
				<div class="modal-header" v-if="false"></div>
				<div class="modal-body">
					<div class="d-flex justify-content-between border-bottom mb-3 pb-2">
						<div class="h5">Daftar Teman</div>
						<button class="btn btn-link m-0 p-0 text-dark" data-dismiss="modal">
							<i class="fi fi-rr-cross"></i>
						</button>
					</div>
					<div class="row my-3 border-bottom">
						<div class="col col-sm col-md"></div>
						<div class="col-8 col-sm-6 col-md-5">
							<img :src="affiliateImg('img')" class="w-100" />
						</div>
						<div class="col col-sm col-md"></div>
						<div class="col-12 text-center">
							<div class="h5">Ajak temanmu, nikmati komisimu!</div>
							<p>
								Dapatkan komisi saldo BosPay sebesar 1% dari ongkos kirim per paket yang dikirimkan oleh temanmu. 
								Komisi hingga jutaan rupiah. Syarat dan ketentuan berlaku.
							</p>
						</div>
					</div>
					<form class="mt-3" @submit.prevent="dontShowAgain">
						<div class="icheck-material-orange icheck-inline">
							<input type="checkbox" id="dontShowAgain" v-model="hideIntro" />
							<label for="dontShowAgain" class="ml-2 font-weight-normal">Jangan tampilkan lagi</label>
						</div>
						<button class="btn btn-block btn-warning text-light">Oke, Saya Mengerti</button>
					</form>
				</div>
				<div class="modal-footer" v-if="false"></div>
			</div>
		</div>
	</div>
	<div class="modal" tabindex="-1" role="dialog" ref="affiliateStep" data-backdrop="static">
		<div class="modal-dialog d-flex align-items-center m-auto" role="document" style="height: 100vh">
			<div class="modal-content">
				<div class="modal-header" v-if="false"></div>
				<div class="modal-body">
					<div class="d-flex justify-content-between border-bottom mb-3 pb-2">
						<div class="h5">Cara Menggunakan Kode Afiliasi</div>
						<button class="btn btn-link m-0 p-0 text-dark" data-dismiss="modal">
							<i class="fi fi-rr-cross"></i>
						</button>
					</div>
					<div class="row mt-2 pb-2 border-bottom">
						<div class="col-auto"><button class="btn btn-warning rounded-circle text-light">1</button></div>
						<div class="col d-flex align-items-center">Kirim kode link afiliasi kamu</div>
					</div>
					<div class="row mt-2 pb-2 border-bottom">
						<div class="col-auto"><button class="btn btn-warning rounded-circle text-light">2</button></div>
						<div class="col d-flex align-items-center">Pastikan teman kamu melakukan pendaftaran dengan memasukkan kode mu</div>
					</div>
					<div class="row mt-2 pb-2 border-bottom">
						<div class="col-auto"><button class="btn btn-warning rounded-circle text-light">3</button></div>
						<div class="col d-flex align-items-center">Pastikan teman kamu mengirimkan paket dari aplikasi bosCOD</div>
					</div>
					<div class="row mt-2 pb-2 border-bottom">
						<div class="col-auto"><button class="btn btn-warning rounded-circle text-light">4</button></div>
						<div class="col d-flex align-items-center">Kamu mendapatkan bonus komisi dari afiliasi teman kamu</div>
					</div>
				</div>
				<div class="modal-footer" v-if="false"></div>
			</div>
		</div>
	</div>
	<div class="modal" tabindex="-1" role="dialog" ref="affiliateList" data-backdrop="static">
		<div class="modal-dialog d-flex align-items-center m-auto" role="document" style="height: 100vh">
			<div class="modal-content">
				<div class="modal-header" v-if="false"></div>
				<div class="modal-body">
					<div class="d-flex justify-content-between">
						<div class="h5">Daftar Teman</div>
						<button class="btn btn-link m-0 p-0 text-dark" data-dismiss="modal">
							<i class="fi fi-rr-cross"></i>
						</button>
					</div>
					<div class="row mt-2">
						<ul class="list-group w-100">
							<li class="list-group-item border-left-0 border-bottom-0 border-right-0 pl-2" v-if="!referralList.length">
								<div class="row">
									<div class="col col-sm col-md"></div>
									<div class="col-8 col-sm-6 col-md-5">
										<img :src="affiliateImg('null')" class="w-100 mb-3" />
									</div>
									<div class="col col-sm col-md"></div>
									<div class="col-12 text-center">
										<div class="h5">Belum Ajak Teman</div>
										<p>
											Kamu belum pernah ajak teman menggunakan kode afiliasi.
											Bagikan kode afiliasi dan dapatkan komisi.
										</p>
									</div>
								</div>
							</li>
							<li class="list-group-item border-left-0 border-bottom-0 border-right-0 pl-2" v-for="affiliated in referralList">
								<div class="row">
									<div class="col-auto"><img :src="'https://ui-avatars.com/api/?name=' + affiliated.nama + '&background=random&color=fff&size=32&length=1&rounded=true&bold-true'" /></div>
									<div class="col">
										<div class="font-weight-bold">{{ affiliated.nama }}</div>
										<small class="text-grey">Bergabung pada {{ shortDate(affiliated.created_on) }}</small>
									</div>
								</div>
							</li>
						</ul>
					</div>
				</div>
				<div class="modal-footer" v-if="false"></div>
			</div>
		</div>
	</div>
	<!-- Form Rekonsiliasi Afiliasi -->
	<div class="modal" tabindex="-1" role="dialog" ref="formPreview" data-backdrop="static">
			<div class="modal-dialog" role="document">
					<form role="form" @submit.prevent="submitForm">
							<div class="modal-content">
							<div class="modal-header">
									<h5>KONFIRMASI REKONSILIASI</h5>
							</div>
							<div class="modal-body">
									<div class="col-7 text-center mx-auto mb-2">
											<h5>Periode :</h5>
											<datepicker v-model="month" @input="setMonth" :options="{format:'mm/yyyy', startView: 'months', minViewMode: 'months', autoclose: true}"/>
									</div>
									<div class="col-7 bank card bg-for-light padding mx-auto">
											<h6>Total kiriman : {{ !formItem.total_kiriman ? 0 : formItem.total_kiriman }}</h6>
											<h6>Estimasi diterima : Rp {{ formatPrice(formItem.total_rekon) }}</h6>
									</div>
							</div>
							<div class="modal-footer">
									<button type="submit" class="btn btn-primary">
											Konfirmasi
											<span v-if="processing" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
									</button>
									<button type="button" class="btn btn-secondary" data-dismiss="modal">
											Tutup
									</button>
							</div>
							<div class="mb-2"></div>
							</div>
					</form>
			</div>
	</div>
	</section>
	<!-- /.content -->
</template>
<script>
import { createTable, authFetch, auth, formatCurrency,openChat } from "@/libs/hxcore";
import $ from "jquery";
import "icheck-material";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import moment from "moment";
import Daterangepicker from "daterangepicker";
import datepicker from '@/components/Datepicker';
import "daterangepicker/daterangepicker.css";
import Swal from "sweetalert2";

export default {
name: "Shipper Afiliasi",
data() {
	return {
		errors: [],
		shipmentAmount: [{label: '1 - 5', num: 5}, {label: '6 - 30', num: 30}, {label: '31 - 100', num: 100}, {label: ' > 100', num: 1000}],
		method: "",
		roles: "",
		groups: [],
		memberType: [],
		userCS: [],
		bank: [],
		filter: {},
		recapAfiliasi: {},
		shipper_id: 0,
		shipper_name: '',
		dt1: moment().startOf("month"),
		dt2: moment().endOf("month"),
			data_type: "pickupTime",
		disabled: false,
		processing: false,
		formTitle: "Tambah Shipper",
		form: {
			courier_id: [],
			name: "",
			username: "",
			display_name: "",
			email: "",
			membership_type_id: "",
			kontak_hp: "",
			bank_id: 0,
			bank_rekening: "",
			bank_atasnama: "",
			alamat: "",
			active: "",
			usercs_id: "",
			shipment_amount: "",
		},
		month: moment().format("MM/YYYY"),
		formItem: {
				total_kiriman: 0,
				estimasi_reward: 0
		},
		hideIntro: false,
		referralList: [],
		referralCode: "",
		referralLink: "",
	};
},
computed: {
	kurirLogo() {
		const fileName = "jne"; //logo.toLowerCase()

		return require(`../assets/img/${fileName}.png`); // the module request
	},
},
components: {
	vSelect,
	datepicker
},
watch:{
	$route (to, from){
		if(to.path === "/shipper/all")
		{
			sessionStorage.filterData='';
			sessionStorage.filterStatus = '';
			this.filter = {};
			this.table.api().ajax.reload();
		}
	}
},
created: function () {
	this.roles = this.$route.meta.roles;
	this.userInfo = auth.user();
	this.shipper_id = this.$route.params.id;
	if(this.shipper_id) {
		this.filter.shipper_id = this.shipper_id;
	}
	if(this.$route.params.filter) {
		this.filter = this.$route.params.filter;
		this.loadAllStatus(this.filter);
	} else {
		this.filter.dt1 = this.dt1.format('YYYY-MM-DD');
		this.filter.dt2 = this.dt2.format('YYYY-MM-DD');
		this.loadAllStatus(this.filter);
	}
	if(this.$route.params.filter) {
		this.filter = this.$route.params.filter;
	}

	if(this.filter.dt1 && this.filter.dt2)
	{
		this.dt1 = moment(this.filter.dt1);
		this.dt2 = moment(this.filter.dt2);
	}
	this.dateStart = this.dt1.format('YYYY-MM-DD');
	this.dateEnd = this.dt2.format('YYYY-MM-DD');
	if(this.filter.gid)
	{
		this.groupId = this.filter.gid;
		this.dateStart = '';
		this.dateEnd = '';
	}
	if(Object.keys(this.filter).length<1)
	{
		if(sessionStorage.filterData)this.filter = JSON.parse(sessionStorage.filterData);
	}
	else{
		sessionStorage.filterData = JSON.stringify(this.filter);
		sessionStorage.filterStatus = 1;
	}
	this.filter.datatype = this.data_type;
},
methods: {
	shortDate: function (date) {
		return moment(date).format('DD/MM/YYYY');
	},
	dontShowAgain: function (ev) {
		$(this.$refs.affiliateIntro).modal("hide");
		localStorage.hideIntro = this.hideIntro;
		ev.preventDefault();
	},
	copyCode: function (e) {
		var self = this;
		navigator.clipboard.writeText(this.referralCode).then(function() {
			$(self.$refs.btnCopy).attr('title','Berhasil menyalin kode referal').tooltip('_fixTitle').tooltip('show').attr('title','Salin kode referal').tooltip('_fixTitle')
			Swal.fire("Berhasil mendapat kode referal", "Gunakan dengan cara <i>paste</i> atau tempel ke kolom Referal Teman di halaman pendaftaran", "success");
		}, function(err) {
			console.error(err);
		});
	},
	copyLink: function (e) {
		var self = this;
		navigator.clipboard.writeText(this.referralLink).then(function() {
			$(self.$refs.btnCopy).attr('title','Berhasil menyalin link pendaftaran referal').tooltip('_fixTitle').tooltip('show').attr('title','Salin link pendaftaran referal').tooltip('_fixTitle')
			Swal.fire("Berhasil mendapat link pendaftaran", "Bagikan ke temanmu dengan cara <i>paste</i> atau tempel link", "success");
		}, function(err) {
			console.error(err);
		});
	},
	mouseOver: function(e){
		let ev = this.$refs;
		$(ev.btntoolTip).tooltip('show');
		// $(ev.btntoolTip).css("cursor", "help");
	},
	affiliateImg(type) {
		return require(`../assets/img/afiliate-${type}.svg`);
	},
	handleClick(e) {
	if (e.target.matches(".link-role")) {
	this.$router.push({ path: "/permit/" + e.target.dataset.id });
	return false;
	}
	if (e.target.matches(".link-order")) {
		let route = this.$router.resolve({
			path: "/shipper/detail/" + e.target.dataset.id
		});
		window.open(route.href,"_blank");
		// return false;
	}
	if (e.target.matches(".link-chat")) {
		openChat(e.target.dataset.to);
		return false;
	}
	},
	setMonth: function (val) {
		this.loadTotalAfiliasi(val);
	},
	loadTotalAfiliasi(month){
		this.formItem.month = month;

		// set data total reward per month
		var params = { shipper_id: this.shipper_id, month: this.month };
		let data = new URLSearchParams(params);

		// get data total reward
		let url = '/shipper/total_reward_afiliasi?'+ data;
		authFetch(url)
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			if (js.data == null) this.formItem = {};
			else this.formItem = js.data;
		});
	},
	showStep: function () {
		const e = this.$refs;
		$(e.affiliateStep).modal("show");
	},
	showList: function () {
		const e = this.$refs;
		$(e.affiliateList).modal("show");
	},
	confirmRecon: function () {
		const er = this.$refs;
		$(er.formPreview).modal("show");
	},
	submitForm: function (ev) {
		const er = this.$refs;
		// const self = this;
		if (!this.month) {
			Swal.fire("Periode belum dipilih!", ``, "error");
		} else {
			this.formItem.shipper_id = this.shipper_id;
			this.formItem.month = this.month;
			var data = Object.keys(this.formItem)
			.map(
				(key) =>
				encodeURIComponent(key) + "=" + encodeURIComponent(this.formItem[key])
			)
			.join("&");
			this.processing = true;
			Swal.fire({
					title: "Konfirmasi Rekonsiliasi?",
					showCancelButton: true,
					confirmButtonText: `Ya`,
					cancelButtonText: "Tidak",
			}).then((result) => {
				if (result.isConfirmed) {
					authFetch("/shipper/rekonsiliasi_afiliasi", {
						method: 'PUT',
						body: data
					})
					.then((res) => {
						return res.json();
					})
					.then((js) => {
						this.processing = false;
						if (js.success) {
								Swal.fire("Proses Berhasil", ``, "success");
								$(er.formPreview).modal("hide");
								this.loadAllStatus(this.filter);
								this.table.api().ajax.reload();
						} else {
								Swal.fire("Proses gagal", ``, "error");
								// this.tbl.api().ajax.reload();
						}
					});
				}
			});
		}
		ev.preventDefault();
	},
	formatPrice(val){
			if(!val || val == 0) return "0";
			return formatCurrency(val);
	},
	dataType(event) {
		this.data_type = event.target.value;
	this.filter.datatype = this.data_type;
	//	 this.table.api().ajax.reload();
	},
	onDate() {
		// var params = { dt1: this.dateStart, dt2: this.dateEnd };
		// var search = new URLSearchParams(params);
		this.table.api().ajax.reload();
	},
	loadAllStatus(params) {
		// console.log("params ", Object.keys(params));
		var data = '';
		if (!params) {
			data = '';
		} else {
			data = Object.keys(params)
				.map(
					(key) => encodeURIComponent(key) + "=" + ((params[key] ===false || params[key] === undefined)?0 : encodeURIComponent(params[key]))
				)
				.join("&");
		}
		// console.log(data);

		let url = '/shipper/affiliasi/recap_afiliasi?'+ data;
		authFetch(url)
		.then((res) => {
			if (res.status === 201) {
			} else if (res.status === 400) {
			}
			return res.json();
		})
		.then((js) => {
			this.recapAfiliasi = js.data;
			this.recapAfiliasi.estimasi_reward = formatCurrency(js.data.estimasi_reward);
		});
	},
},
mounted() {
	const e = this.$refs;
	var self = this;
	new Daterangepicker(
		this.$refs.daterange,
		{
			startDate: !this.dt1 ? moment().day(-31) : this.dt1,
			endDate: !this.dt2 ? moment() : this.dt2,
			locale: {
				format: 'DD/MM/YYYY'
			},
		},
		function (d1, d2) {
			self.dateStart = d1.format("YYYY-MM-DD");
			self.dateEnd = d2.format("YYYY-MM-DD");
			self.data_type = self.data_type;
			self.filter.datatype = self.data_type;
			self.filter.dt1 = self.dateStart;
			self.filter.dt2 = self.dateEnd;
			self.onDate();
			var json = { dt1: self.filter.dt1, dt2: self.filter.dt2, shipper_id:self.shipper_id };
			self.loadAllStatus(json);
		}
	);
	authFetch("/users/profile/affiliated" + (this.$route.params.id ? '/' + this.$route.params.id : ''))
	.then((res) => {
		if (res.status === 201) {} else if (res.status === 400) {}
		return res.json();
	})
	.then((js) => {
		if (js.success) {
			this.shipper_name = js.name;
			this.referralList = js.data;
			this.referralCode = js.referral_code;
			this.referralLink = js.url;
		} else {
			// Swal.fire("Kamu belum berhasil ajak satu pun teman", ``, "error");
		}
	});
	//console.log('roles', this.$route.params.roles);
	this.table = createTable(e.tableuser, {
		title: "",
		roles: ['read'],
		ajax: "/shipper/affiliasi/afiliasi_by_id",
		frame: true,
		scrollX: true,
		processing: true,
		autoWidth:false,
		filter: false,
		language: {
		processing: '<i class="fa fa-spinner fa-5x fa-spin" style="font-size:50px;color:black;"></i>'},
		paramData: function (d) {
			var filter = JSON.parse(JSON.stringify(self.filter));
			//d = filter;
			//d.dev = filter.delivered;
			d.dt1 = self.dateStart;
			d.dt2 = self.dateEnd;
			d.datatype = self.data_type;
			var filter = JSON.parse(JSON.stringify(self.filter));
			for (const key in filter) {
				d[key] = filter[key] === false ? 0 : filter[key];
			}
		},
		columns: [
			{ data: "payment_status",width: '12%',
			render: function (data, type, row, meta) {
				if (data == 1) {
					return '<span class="badge badge-success">Sukses</span>'
				} else if(data == 0) {
					return '<span class="badge badge-info">Pending</span>'
				}
			} },
			{ data: "no_resi"},
			{ data: "joined_on",
			render: function (data, type, row, meta) {
				return moment(row.created_on).format("DD/MM/YYYY HH:mm");
			}, },
			{ 
				data: "total_reward", 
				className: "text-right",
				render: function (data, type, row, meta) {
					var total_reward = data?data:0;
					return `Rp. ${total_reward}`;
				},
				},
		],
		filterBy: [1],
		rowCallback: function (row, data) {
		},
		buttonClick: (evt) => {
			if (evt.role == "create") {
				self.form = {};
				self.method = "POST";
				self.errors = [];
				self.formTitle = "Tambah Shipper";
				$(e.formDialog).modal("show");
			} else if (evt.role == "update" && evt.data) {
				//get kurir
				let data = evt.data.kurir; //nama kurir
				let id_kurir = evt.data.courier_id; //id kurir
				let len = data.length;
				let len2 = id_kurir.length;

				if (evt.data.kurir == []) {
					self.form.courier_id = "";
				} else {
					self.form.courier_id = [];
					if (len < 1 || len2 < 1) {
						self.form.courier_id;
					} else {
						for(let i = 0; i < len && i < len2; i++)
						{
							self.form.courier_id.push({
								label: data[i],
								courier_id: id_kurir[i],
							});
						}
					}
				}

				self.form.id = evt.data.id;
				self.form.name = evt.data.name;
				self.form.display_name = evt.data.display_name;
				self.form.username = evt.data.username;
				self.form.email = evt.data.email;
				self.form.membership_type_id = evt.data.membership_type_id;
				self.form.kontak_hp = evt.data.kontak_hp;
				self.form.bank_id = evt.data.bank_id;
				self.form.bank_rekening = evt.data.bank_rekening;
				self.form.bank_atasnama = evt.data.bank_atasnama;
				self.form.alamat = evt.data.alamat;
				self.form.usercs_id = evt.data.usercs_id;
				self.form.shipment_amount = evt.data.shipment_amount;
				self.form.marking = evt.data.marking;

				if (evt.data.active == 0 || !evt.data.active) {
					self.form.active = false;
				} else {
					self.form.active = true;
				}
				self.method = "PUT";
				self.errors = [];
				self.formTitle = "Edit Shipper";
				$(e.formDialog).modal("show");
			} else if (evt.role == "delete" && evt.data) {
				self.form = evt.data;
				authFetch("/shipper/" + evt.data.id, {
					method: "DELETE",
					body: "id=" + evt.data.id,
				})
				.then((res) => {
					return res.json();
				})
				.then((js) => {
					this.table.api().ajax.reload();
				});
			}
		},
		initComplete: function () {
			$('.loading-overlay').removeClass('show');
			if (!localStorage.hideIntro && auth.user().role_id > 10) {
				$(e.affiliateIntro).modal("show");
			}
		},
	});
},
};
</script>
<style type="text/css">
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

/* Firefox */
input[type="number"] {
-moz-appearance: textfield;
}

@media (max-width: 783.98px) {
.mt-xs-2 {
	margin-top: 0.5rem !important;
}
}

@media (min-width: 784px) {
.w-49 {
	margin-left: 0.5rem !important;
	width: auto !important;
	height: fit-content;
}
}
</style>